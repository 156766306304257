import React from "react";
import "./info.css"

function Infos(){

    return(
        <>
            <h2>News</h2>
        </>
    )
}

export default Infos